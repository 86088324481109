h1.search-results-title {
    font-size: 24px;
    font-weight: bold;
    line-height: 33px;
}
#menuItemsDiv button.mapviewbtn,
#menuItemsDiv button.listviewbtn,
#venueItemsDiv button.mapviewbtn,
#venueItemsDiv button.listviewbtn {
    color: #02843D;
    border: 1px solid #02843D !important;
    padding: 5px 10px !important;
    border-radius: 4px !important;
}
#menuItemsDiv button.mapviewbtn > span,
#menuItemsDiv button.listviewbtn > span,
#venueItemsDiv button.mapviewbtn > span,
#venueItemsDiv button.listviewbtn > span {
    font-style: normal;
    font-weight: 600 !important;
    font-size: 1rem;
    line-height: 1.5rem;
}
#menuItemsDiv .sortbytextclass.sortbytextmobo > label {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
}
#menuItemsDiv .sortbytextclass.sortbytextmobo .basic-sort-by-label,
#venueItemsDiv .sortbytextclass.sortbytextmobo .basic-sort-by-label {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
}

#search_filter_divmobile > .search_filter_rating {
    margin-top: 20px;
}
#search_filter_divmobile > #search_filter_price {
    padding-bottom: 1.5rem;
}
.filter_price_range_slider .slidecontainer.slidecontainerNew {
	padding-left: 5px;
	padding-right: 10px;
}
.filter_price_range_slider .slider.slider-horizontal .slider-track {
	border-radius: 30px !important;
}
/* new slider */
.filter_price_range_slider .slider-handle.max-slider-handle > .low {
    right: 0;
    white-space: nowrap;
}
.filter_price_range_slider .slider-handle.max-slider-handle > p,
.filter_price_range_slider .slider-handle.min-slider-handle > p {
	font-size: 0.75rem;
	color: #000;
	width: auto !important;
	white-space: nowrap;
}
/* #filter_price_range_slider > #search_filter_price > .filter_price_range_slider {

} */
#menuItemsDiv #sortorder_menuitem {
	max-width: 200px;
	color: #4A4A4A;
}
#menuItemsDiv #sortorder_menuitem input.sortorders,
#venueItemsDiv #sortorder_venue input.sortorders {
	-webkit-appearance: none;
	width: 1rem;
    height: 1rem;
    border-radius: 5px;
    background-color: #BDC6C1;
}
#menuItemsDiv #sortorder_menuitem input.sortorders:checked:after,
#venueItemsDiv #sortorder_venue input.sortorders:checked:after {
	content: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAANCAYAAACdKY9CAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABzSURBVHgBxY9RDYAwDEQrBQmTgAQk4AAcMAk4mBQkTMIkIOFoQxNKUpLyQXjJJct2196IvgBAx5rfBApO+oh5VHOJmKXKzmpytg8Ta3AC1a2iE2RSMneLmrO3OtnVKqFR4HNVg7j1fghlXGSKwMaVtdFvHNTAl1s+KAl5AAAAAElFTkSuQmCC');
	font-size: 14px;
	position: absolute;
	top: -1px;
    left: 2px;
	color: #fff;
}
#menuItemsDiv #sortorder_menuitem input.sortorders:checked, 
#venueItemsDiv #sortorder_venue input.sortorders:checked {
	background-color: #02843D;
}
/* Menu Item */
#search-menuItemsDiv .menucard .homepage-prefs-div {
	margin: 0 !important;
	padding-left: 10px !important;
	padding-right: 10px !important;
}
/* #search-menuItemsDiv .menucard .homepage-prefs-div {
	
} */
#search-menuItemsDiv .menucard .homepage-prefs-div a {
	margin-left: 0 !important;
	margin-bottom: 0.5rem;
}
#search-menuItemsDiv .menucard .homepage-prefs-div a .rate-item-home {
	width: 100%;
}

#missing-restaurant-div1 #missing_restaurant_1 {
    background-color: #02843D;
    border-radius: 4px;
    min-width: 191px;
    color: #fff !important;
}

#menuItemsDiv .sortbytextclass.sortbytextmobo {
    position: absolute;
}
@media (max-width: 767.98px) {
    h1.search-results-title {
        font-size: 18px;
        line-height: 27px;
    }
.sortbytextclass{z-index:99}
    #menuItemsDiv .sortbytextclass.sortbytextmobo .basic-sort-by-label,
    #venueItemsDiv button.basic-sort-by-label {
		font-size: 14px !important;
	}
	#filter_locals_rating {
		border-bottom: 0.3px solid #BDC6C1;
		margin-bottom: 1rem;
	}
	.find.mt-md-5.div-orientation > div {
		margin-top: 0!important;
        height: 135px!important;
	}
    #menuItemsDiv .sortbytextclass.sortbytextmobo {
        position: static;
    }
}
@media only screen and (max-width: 1024px) {
    /* font size for mobile devices only */
    .font-larger-mobile-13 {
        font-size:16px;
    }

    .font-larger-mobile-10 {
        font-size:14px;
    }
} 

/* not supported by Android devices (device-width) */
/*@media only screen and (max-device-width : 1024px) {
}*/
